class FormHelper {
  static selectFilterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  static onFinishFailed = (errorInfo) => {
    console.error('Failed:', errorInfo);
  };

  static resetFormsFieldErrors = (form, onlyResetFields) => (changedFields) => {
    if (changedFields[0]) {
      const name = changedFields[0].name[0];
      if (!onlyResetFields) {
        form.setFields([
          {
            name,
            errors: []
          }
        ]);
      } else {
        if (onlyResetFields.includes(name)) {
          form.setFields([
            {
              name,
              errors: []
            }
          ]);
        }
      }
    }
  };

  static normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  static beforeUpload = () => false;
}

export default FormHelper;
