import React from 'react';
import PropTypes from 'prop-types';
import Bot from '../../services/services/Bot';
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    (async () => {
      await Bot.sendTelegramMessage(`
          Front Error
          Error: ${error}
          Message: ${info.componentStack}
          Time: ${new Date().toLocaleString()}
        `);
      if (process.env.NODE_ENV !== 'development') {
        window.location.href = '/500';
      }
    })();
  }

  render() {
    const { hasError } = this.state;
    const { fallback, children } = this.props;
    if (hasError) {
      // You can render any custom fallback UI
      return fallback;
    }

    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  fallback: PropTypes.node.isRequired
};

export default ErrorBoundary;
