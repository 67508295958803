import React, { Suspense, lazy, useEffect } from 'react';
import './styles/global.scss';
import './App.css';
import { RecoilRoot } from 'recoil';
import { ConfigProvider } from 'antd';
import { useNavigate } from 'react-router-dom';
import Loader from './components/loader';
import dayjs from 'dayjs';
import 'dayjs/locale/hy-am';
import ErrorBoundary from './components/ErrorBoundary';
import axios from 'axios';
import UIHelper from './helpers/UIHelper';
import Bot from './services/services/Bot';
import am from 'antd/locale/hy_AM';

const Pages = lazy(() => import('./pages'));
dayjs.locale('hy-am');
function App() {
  const navigate = useNavigate();

  useEffect(() => {
    const myInterceptor = axios.interceptors.response.use(
      (response) => {
        const isSignInResponse = response?.config?.url.includes('/auth');
        const isCreateOrUpdateResponse = ['post', 'put', 'patch'].includes(
          response?.config?.method
        );

        if (response.status < 300 && !isSignInResponse && isCreateOrUpdateResponse) {
          UIHelper.openNotificationWithIcon('success', 'Գործողությունը հաջողությամբ ավարտված է');
        }
        return response;
      },
      (error) => {
        const isSignInResponse = error?.response.config?.url.includes('/auth');
        if (error?.response?.status >= 500) {
          UIHelper.serverError();
          Bot.sendTelegramMessage(`
          Server error
          Error: ${error?.response?.status}
          Message: ${error?.response?.data?.message}
          Time: ${new Date().toLocaleString()}
        `);

          if (process.env.NODE_ENV !== 'development') {
            navigate('/500');
          }
        }
        if (error?.response?.status === 404 && !isSignInResponse) {
          if (process.env.NODE_ENV !== 'development') {
            navigate('/404');
          }
        }
        if (error?.response?.status === 403) {
          // window.location.href = '/403';

          if (process.env.NODE_ENV !== 'development') {
            navigate('/403');
          }
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.response.eject(myInterceptor);
    };
  }, [navigate]);

  return (
    <Suspense fallback={<Loader />}>
      <RecoilRoot>
        <ConfigProvider locale={am}>
          <ErrorBoundary fallback={<p>Something went wrong</p>}>
            <Pages />
          </ErrorBoundary>
        </ConfigProvider>
      </RecoilRoot>
    </Suspense>
  );
}

export default App;
