class Bot {
  static async sendTelegramMessage(message) {
    try {
      await fetch(`https://api.telegram.org/bot${process.env.REACT_APP_BOT_TOKEN}/sendMessage`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          chat_id: process.env.REACT_APP_BOT_CHAT_ID,
          parse_mode: 'HTML',
          text: message
        })
      });
    } catch (e) {
      console.error('ee', e);
    }
  }
}

export default Bot;
