import { notification } from 'antd';
import FormHelper from './FormHelper';

class UIHelper {
  static openNotificationWithIcon = (type, message, description) => {
    notification.config({
      duration: 3,
      placement: 'top'
    });
    notification[type]({
      message,
      description
    });
  };
  static serverError = () => {
    notification.config({
      duration: 4
    });
    notification['error']({
      message: 'Սխալ, խնդրում ենք կապվել ղեկավարության հետ'
    });
  };

  static getListRoute = (pathname, skipPathsLength = 2) => {
    const split = pathname.split('/').filter(Boolean);
    const mainList = split.slice(0, split.length - skipPathsLength);
    return `/${mainList.join('/')}`;
  };

  static typeNumber = (evt) => {
    let key = evt.keyCode || evt.which;
    key = String.fromCharCode(key);
    if (key.length === 0) return;

    if (!/^[0-9,\b]+$/.test(evt.key)) {
      evt.preventDefault();
    }
  };

  static typeInteger = (evt) => {
    let key = evt.keyCode || evt.which;
    key = String.fromCharCode(key);
    if (key.length === 0) return;

    if (!/^[0-9\b]+$/.test(evt.key)) {
      evt.preventDefault();
    }
  };

  static correctionNumber = (field) => {
    try {
      const res = parseFloat(field.toString().replace(',', '.'));
      if (isNaN(res)) {
        return 0;
      }
      return Number(res);
    } catch (e) {
      return 0;
    }
  };

  static removeDot = (field) => {
    if (typeof field === 'string') {
      return field.replace('.', '');
    }
    return field;
  };

  static formattedNumber = (num) => {
    try {
      return num.replace(/[.,]00$/, '');
    } catch (e) {
      return 0;
    }
  };

  static percentage(percent, total) {
    return Number(((percent / 100) * total).toFixed(2));
  }

  static assignTire = (columns) => {
    columns?.map((i) => {
      if (!i.render) {
        i.render = (data) => {
          if (typeof data === 'number') {
            return data;
          }
          return data || '-';
        };
      } else {
        const oldRender = i.render;
        i.render = (data, row) => {
          const renderedData = oldRender(data, row);
          if (typeof renderedData === 'number') {
            return renderedData;
          }
          return renderedData || '-';
        };
      }
    });

    return columns;
  };

  static replaceDotToComma = (value) => {
    if (!value) {
      return value;
    }
    const v = value.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
    return v.replace ? v.replace('.', ',') : v;
  };

  static numberWithDot(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }

  static getSelectContainerProp = (element = 'parentElement', customElement = false) => {
    return {
      getPopupContainer: (e) => (customElement ? element : e[element])
    };
  };

  static getSelectSearchProps = (optionFilterProp = 'title') => {
    return {
      optionFilterProp,
      showSearch: true,
      filterOption: FormHelper.selectFilterOption
    };
  };
}

export default UIHelper;
